import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./tabs/tabs.module').then((m) => m.TabsPageModule),
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  { path: '**', redirectTo: 'home', pathMatch: 'full' },
  {
    path: 'chat',
    loadChildren: () =>
      import('./chat/chat.module').then((m) => m.ChatPageModule),
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./login/login.module').then((m) => m.LoginPageModule),
  },
  {
    path: 'register',
    loadChildren: () =>
      import('./register/register.module').then((m) => m.RegisterPageModule),
  },
  {
    path: 'phone-auth',
    loadChildren: () =>
      import('./phone-auth/phone-auth.module').then(
        (m) => m.PhoneAuthPageModule
      ),
  },
  {
    path: 'forgot-password',
    loadChildren: () =>
      import('./forgot-password/forgot-password.module').then(
        (m) => m.ForgotPasswordPageModule
      ),
  },
  {
    path: 'country-code',
    loadChildren: () =>
      import('./country-code/country-code.module').then(
        (m) => m.CountryCodePageModule
      ),
  },
  {
    path: 'set-account',
    loadChildren: () =>
      import('./set-account/set-account.module').then(
        (m) => m.SetAccountPageModule
      ),
  },
  {
    path: 'search',
    loadChildren: () =>
      import('./search/search.module').then((m) => m.SearchPageModule),
  },
  {
    path: 'edit-profile',
    loadChildren: () =>
      import('./edit-profile/edit-profile.module').then(
        (m) => m.EditProfilePageModule
      ),
  },
  {
    path: 'settings',
    loadChildren: () =>
      import('./settings/settings.module').then((m) => m.SettingsPageModule),
  },
  {
    path: 'followers',
    loadChildren: () =>
      import('./followers/followers.module').then((m) => m.FollowersPageModule),
  },
  {
    path: 'following',
    loadChildren: () =>
      import('./following/following.module').then((m) => m.FollowingPageModule),
  },
  {
    path: 'my-posts',
    loadChildren: () =>
      import('./my-posts/my-posts.module').then((m) => m.MyPostsPageModule),
  },
  {
    path: 'language',
    loadChildren: () =>
      import('./language/language.module').then((m) => m.LanguagePageModule),
  },
  {
    path: 'add-quote',
    loadChildren: () =>
      import('./add-quote/add-quote.module').then((m) => m.AddQuotePageModule),
  },
  {
    path: 'add-poll',
    loadChildren: () =>
      import('./add-poll/add-poll.module').then((m) => m.AddPollPageModule),
  },
  {
    path: 'add-post',
    loadChildren: () =>
      import('./add-post/add-post.module').then((m) => m.AddPostPageModule),
  },
  {
    path: 'themes',
    loadChildren: () =>
      import('./themes/themes.module').then((m) => m.ThemesPageModule),
  },
  {
    path: 'notifications',
    loadChildren: () =>
      import('./notifications/notifications.module').then(
        (m) => m.NotificationsPageModule
      ),
  },
  {
    path: 'forum-post',
    loadChildren: () =>
      import('./forum-post/forum-post.module').then(
        (m) => m.ForumPostPageModule
      ),
  },
  {
    path: 'bookmark',
    loadChildren: () =>
      import('./bookmark/bookmark.module').then((m) => m.BookmarkPageModule),
  },
  {
    path: 'nearby-by',
    loadChildren: () =>
      import('./nearby-by/nearby-by.module').then((m) => m.NearbyByPageModule),
  },
  {
    path: 'interest',
    loadChildren: () =>
      import('./interest/interest.module').then((m) => m.InterestPageModule),
  },
  {
    path: 'forum-followers',
    loadChildren: () =>
      import('./forum-followers/forum-followers.module').then(
        (m) => m.ForumFollowersPageModule
      ),
  },
  {
    path: 'range',
    loadChildren: () =>
      import('./range/range.module').then((m) => m.RangePageModule),
  },
  {
    path: 'create-forum',
    loadChildren: () => import('./admin/create-forum/create-forum.module').then( m => m.CreateForumPageModule)
  },
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
