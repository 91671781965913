import { Component, Inject, NgZone } from '@angular/core';
import {
  Platform,
  NavController,
  ModalController,
  AlertController,
} from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { APP_CONFIG, AppConfig } from './app.config';
import { Constants } from './models/constants.models';
import {
  PushNotifications,
  PushNotificationSchema,
  Token,
} from '@capacitor/push-notifications';
import { FCM } from '@capacitor-community/fcm';
import { SplashScreen } from '@capacitor/splash-screen';
import { Router } from '@angular/router';
import { MyEvent } from './providers/myevent';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  themenote: any;
  rtlSide = 'left';
  selectedIndex: any;
  appPages: any;
  token: string = '';
  remoteToken: string;
  notifications: PushNotificationSchema[] = [];

  constructor(
    @Inject(APP_CONFIG) public config: AppConfig,
    private platform: Platform,
    private navCtrl: NavController,
    private zone: NgZone,
    public router: Router,
    public alertCtrl: AlertController,
    private translate: TranslateService,
    private myEvent: MyEvent
  ) {
    this.initializeApp();
    this.myEvent.getLanguageObservable().subscribe((value) => {
      this.navCtrl.navigateRoot(['./']);
      this.globalize(value);
    });
  }

  initializeApp() {
    this.platform.ready().then(() => {
      SplashScreen.hide();
      let defaultLang = window.localStorage.getItem(
        Constants.KEY_DEFAULT_LANGUAGE
      );
      this.globalize(defaultLang);
      setTimeout(() => {
        SplashScreen.hide();
      }, 2000);
      // Check if user is logged In
      if (localStorage.getItem('isLoggedIn') === 'true') {
        this.router.navigateByUrl('/tabs/home', {
          skipLocationChange: true,
          replaceUrl: true,
        });

        // Else navigate to Login Page
      } else {
        this.router.navigateByUrl('/login', {
          skipLocationChange: true,
          replaceUrl: true,
        });
      }

      // Push Notification Permission for iOS
      PushNotifications.requestPermissions().then((permission) => {
        if (permission.receive) {
          PushNotifications.register();
        } else {
          console.log('No Permission');
        }
      });

      // add Listener and save token locally
      // On success, we should be able to receive notifications
      PushNotifications.addListener('registration', (token: Token) => {
        //alert('Push registration success, token: ' + token.value);
      });

      /*PushNotifications.addListener(
        'pushNotificationReceived',
        (notification: PushNotificationSchema) => {
          console.log('notification ' + JSON.stringify(notification));
          this.zone.run(() => {
            this.notifications.push(notification);
          });
        }
      );*/

      this.getToken();

      // Some issue with our setup and push will not work
      PushNotifications.addListener('registrationError', (error: any) => {
        //alert('Error on registration: ' + JSON.stringify(error));
      });

      // on received notification capture datas
      PushNotifications.addListener(
        'pushNotificationReceived',
        (notification) => {
          console.log(JSON.stringify(notification));
          const data = notification.data;
          //const title = notification.title;
          //const body = notification.body;
        }
      );

      PushNotifications.register();
    });
  }

  getToken() {
    FCM.getToken()
      .then((result) => {
        this.remoteToken = result.token;
        localStorage.setItem('fcm', this.remoteToken);
      })
      .catch((err) => console.log(err));
  }

  globalize(languagePriority) {
    this.translate.setDefaultLang('DE');
    let defaultLangCode = this.config.availableLanguages[0].code;
    this.translate.use(
      languagePriority && languagePriority.length
        ? languagePriority
        : defaultLangCode
    );
    this.setDirectionAccordingly(
      languagePriority && languagePriority.length
        ? languagePriority
        : defaultLangCode
    );
  }

  setDirectionAccordingly(lang: string) {
    switch (lang) {
      case 'ar': {
        this.rtlSide = 'rtl';
        break;
      }
      default: {
        this.rtlSide = 'ltr';
        break;
      }
    }
  }
}
