import { InjectionToken } from '@angular/core';
import { Constants } from './models/constants.models';

export let APP_CONFIG = new InjectionToken<AppConfig>('app.config');

export interface AppConfig {
  availableLanguages: Array<{ code: string; name: string }>;
}

export const BaseAppConfig: AppConfig = {
  availableLanguages: [
    {
      code: 'US',
      name: 'English',
    },
    {
      code: 'DE',
      name: 'Deutsch',
    },
    {
      code: 'DO',
      name: 'Español',
    },
	{
		code: 'CH',
		name: 'Schweiz',
	  }
  ],
};
